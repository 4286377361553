import React, { Component } from 'react';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import PageHeading from '../components/PageHeading/PageHeading';
import TitleBar from '../components/TitleBar/TitleBar';

class MobileAppPrivacy extends Component {
  render() {
    return (
      <Layout>
        <Helmet title="VEYM | Mobile App Privacy" />
        <PageHeading>Mobile App Privacy</PageHeading>

        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Mobile App Privacy</BreadcrumbItem>
          </Breadcrumb>
        </Container>

        <br />

        <Container>
          <br />

          <TitleBar>About VEYM</TitleBar>
          <br />
          <p>
            The Vietnamese Eucharistic Youth Movement (VEYM) is a non-profit
            faith based organization with aims to train youth to become
            well-rounded individuals within society and ideal Christians. The
            VEYM is a branch of the Apostleship of Prayer, now known as the
            Pope’s Worldwide Prayer Network, an official pontifical service
            under the Holy See. At present, there are over 130 VEYM Chapters at
            parishes across the country with approximately 2500 youth leaders
            and over 25,000 youth members. At the center of every VEYM’s
            activity is the Eucharist.
          </p>
          <br />

          <TitleBar>About VEYM Mobile App</TitleBar>
          <br />
          <p>
            This app aims to improve and increase VEYM members’ effort in living
            the Eucharistic Day (3 moments of the day) each day through prayers,
            spiritual communions, sacrifices, and apostolic works, as well as
            other religious devotions. Latest news, organizational initiatives,
            useful training resources, events, and Weekly Gospel Lessons are
            also available through this app.
          </p>
          <br />

          <TitleBar>VEYM Mobile App Privacy Statement</TitleBar>
          <br />
          <p>
            The VEYM Mobile App collects device unique identifier and
            potentially personally identifiable data when some of its features
            are used. Specifically the SNTT feature of the app, when used and
            submitted, will send data to our backend servers for statistical
            purposes.
          </p>
        </Container>
      </Layout>
    );
  }
}

export default MobileAppPrivacy;
